import axios from 'axios';

import { authHeader } from '../auth-header';
import { fileUploadHeader } from '../file-upload';
import { API_URL } from '../api-utils';

export const documentService = {
    fetchDocument,
    addDocument,
    fetchSingleDocument,
    editDocument,
    deleteDocument,
    addDocumentFile,
    deleteDocumentFile,
    listDocument,
    searchDocument,
    documentDetail,
    relatedDocument,
    purchasedDocument,
    fetchDocumentFile,
    downloadFile,
    subjectDocument,
    digitalDocument,
    physicalDocument,
    changeDocType,
    fetchDocumentType
}

async function fetchDocumentType() {

    const headers = {
        'Accept': 'application/json'
    }

    return await axios.get(`${API_URL}/documents/document_types/`, {
        headers: headers
    })
}


async function changeDocType(data) {

    return await axios.post(`${API_URL}/documents/change_document_type/${data['slug']}/`, data['payload'], { headers: authHeader() })
}


async function physicalDocument(currentPage) {

    const headers = {
        'Accept': 'application/json'
    }

    return await axios.get(`${API_URL}/documents/physical_documents/?page=${currentPage}`, {
        headers: headers
    })
}


async function digitalDocument(payload) {

    const headers = {
        'Accept': 'application/json'
    }

    return await axios.get(`${API_URL}/documents/digital_documents/?minPrice=${payload['minPrice']}&maxPrice=${payload['maxPrice']}&year=${payload['year']}&region=${payload['region']}&classe=${payload['classe']}&page=${payload['currentPage']}`, {
        headers: headers
    })
}

async function subjectDocument(payload) {
    const headers = {
        'Accept': 'application/json'
    }

    return await axios.get(`${API_URL}/documents/subject_document/${payload['slug']}/?minPrice=${payload['minPrice']}&maxPrice=${payload['maxPrice']}&year=${payload['year']}&region=${payload['region']}`, {
        headers: headers
    })
}

async function downloadFile(payload) {

    return await axios.post(
        `${API_URL}/documents/download_file/`,
        payload,
        { 
            headers: authHeader(),
            responseType: 'blob'
        }
    )

}

async function fetchDocumentFile(slug) {
    return await axios.get(`${API_URL}/documents/document_download_list/${slug}`, {
        headers: authHeader()
    })
}


async function purchasedDocument() {
    return await axios.get(`${API_URL}/documents/purchased_document`, {
        headers: authHeader()
    })
}

// pub
async function relatedDocument(slug) {
    const headers = {
        'Accept': 'application/json'
    }

    return await axios.get(`${API_URL}/documents/related_documents/${slug}`, {
        headers: headers
    })
}


async function searchDocument(payload) {

    const headers = {
        'Accept': 'application/json'
    }
    
    return await axios.get(`${API_URL}/documents/document_search/?query=${payload['query']}&ip=${payload['ip']}&device=${payload['device']}`, {
        headers: headers
    })
}

async function listDocument(currentPage) {

    const headers = {
        'Content-Type': 'application/json'
    }

    return await axios.get(`${API_URL}/documents/pub/list/?page=${currentPage}`, {
        headers: headers
    })
}


async function documentDetail(slug) {

    const headers = {
        'Accept': 'application/json'
    }

    return await axios.get(`${API_URL}/documents/document-detail/${slug}`, {
        headers: headers
    })
}


async function deleteDocumentFile(fileId) {
    return await axios.delete(`${API_URL}/documents/delete_document_file/${fileId}`, {
        headers: authHeader()
    })
}

async function addDocumentFile(payload) {

    return await axios.post(`${API_URL}/documents/add_document_file/`, payload, { headers: fileUploadHeader() })
}


async function deleteDocument(documentId) {

    return await axios.delete(`${API_URL}/documents/document/${documentId}`, {
        headers: authHeader()
    })
}

async function editDocument(payload, documentId) {

    return await axios.put(`${API_URL}/documents/document/${documentId}/`, payload, { headers: fileUploadHeader() })
}


async function fetchSingleDocument(documentId) {

    return await axios.get(`${API_URL}/documents/document/${documentId}/detail`, {
        headers: authHeader()
    })
}

async function addDocument(payload) {

    return await axios.post(`${API_URL}/documents/document/`, payload, { headers: fileUploadHeader() })
}


async function fetchDocument(params) {

    const page = params['page'] + 1

    return await axios.get(
        `${API_URL}/documents/document/?page=${page}&query=${params['query']}&type=${params['document_type']}&classe=${params['classe']}&subject=${params['subject']}`, 
        {
        headers: authHeader()
    
    })
}